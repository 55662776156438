/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql, withPrefix } from "gatsby"

function SEO({ description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      {/* <script type="module" src="https://unpkg.com/@google/model-viewer/dist/model-viewer.min.js" defer />
			<script nomodule src="https://unpkg.com/@google/model-viewer/dist/model-viewer-legacy.js" defer />
			<script src="https://unpkg.com/@webcomponents/webcomponentsjs@2.1.3/webcomponents-loader.js" defer />

			<script src="https://unpkg.com/intersection-observer@0.5.1/intersection-observer.js" defer />

			<script src="https://unpkg.com/resize-observer-polyfill@1.5.0/dist/ResizeObserver.js" defer />

			<script src="https://unpkg.com/fullscreen-polyfill@1.0.2/dist/fullscreen.polyfill.js" defer />

			<script src="https://unpkg.com/@magicleap/prismatic/prismatic.min.js" defer />

			<script src="https://unpkg.com/focus-visible@5.0.2/dist/focus-visible.js" defer />
			<script src={withPrefix('sharpspring.js')} type="text/javascript" defer /> */}
      {/* <script src={withPrefix('sp_form.js')} type="text/javascript" />
			<script src="https://koi-3QNMYMXMQA.marketingautomation.services/client/noform.js?ver=1.24" defer /> */}
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `es`,
  meta: [],
  description: `Tenemos como principio fundamental la estructuración y optimización de espacios sostenibles a través del diseño y la innovación. Creemos firmemente en la funcionalidad que el diseño debe tener sobre la arquitectura.`,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
